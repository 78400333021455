/*  ==========================================================================
    FOOTER
    Styling for the lowest part of the website
    ========================================================================== */

.bn-footer {
  position: relative;
  visibility: visible !important;
  z-index: $unit__zi--footer;
  // type 1
  &.bn-footer--v1 {
    @include footer--v1();
  }
  // type 2
  &.bn-footer--v2 {
    @include footer--v2();
  }
  // type 3
  &.bn-footer--v3 {
    @include footer--v3();
  }
  // DEKO
  .bn-deko {
    top: -475px;
    right: 0;
    transform: scaleX(-1);
    width: 400px;
    z-index: 0;
  }
  // top references
  .bn-top-references {
    &.bn-top-references--mobile {
      &.bn-top-references--mobile-v1 {
        bottom: 40px;
        display: none;
        position: fixed;
        right: 40px;
        text-align: right;
        z-index: $unit__zi--footer-top-references;

        .bn-top-references__item {
          background-color: $clr__ci--primary;
          border-radius: 100%;
          color: $clr__typo--secondary;
          cursor: pointer;
          display: none;
          font-size: 2.5em;
          height: 50px;
          line-height: 48px;
          text-align: center;
          width: 50px;
          align-items: center;
          justify-content: center;
          @if $tgl__use-border {
            border: 1px solid $clr__white;
          } @else {
            box-shadow: 0px 3px 6px #00000029;
          }

          .bn-icon {
            &.bn-icon--minus {
              display: none;
            }
          }


          &.bn-top-references__item--trigger {
            display: inline-flex;

            &.bn-top-references__item--act {
              .bn-icon {
                &.bn-icon--plus {
                  display: none;
                }

                &.bn-icon--minus {
                  display: inline-block;
                }
              }
            }
          }

          &:first-child {
            margin-top: 0;
          }
        }

        .bn-top-references__container {
          margin-bottom: 10px;


          &.bn-top-references__container--cSecondary {

            > * {
              background-color: $clr__ci--secondary !important;
            }
            &:hover {
              > * {
                background-color: $clr__ci--primary!important;
              }
            }
          }
          &.bn-top-references__container--cCallToAction {

            > * {
              background-color: $clr__cta--primary !important;
            }
            &:hover {
              > * {
                background-color: $clr__ci--primary !important;
              }
            }
          }

          &:hover > * {
            background-color: $clr__ci--secondary !important;
          }

          .bn-top-references__description {
            display: none;
          }

          &.bn-top-references__container--visible {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;

            .bn-top-references__item {
              display: flex !important;
            }

            .bn-top-references__description {
              display: block !important;
              margin-right: 10px;
              padding: 5px 8px;
              font-size: 1.4em;
              background-color: $clr__ci--primary;
              flex: 1;
              text-align: center;
              color: $clr__typo--secondary;
              @if $tgl__use-border {
                border: 1px solid $clr__white;
              } @else {
                box-shadow: 0px 3px 6px #00000029;
              }
              text-transform: uppercase;
            }
          }
        }
      }
      &.bn-top-references--mobile-v3 {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        justify-content: center;
        background-color: $clr__ci--primary;
        z-index: 100;
        @if $tgl__use-border {
          border-top: 1px solid $clr__ci--primary;
        } @else {
          box-shadow: 0px -3px 6px #00000029;
        }
        @include top-references-bar();
        @include top-references-colors();
      }

    }
  }
  // print address
  .bn-address-print {
    display: none;
  }
  // Footer Icons
  .bn-footer-icons {
    font-size: 6em;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    a {
      margin: 0 20px;
      margin-top: 10px;
      position: relative;
      &:hover {
        opacity: 0.7;
      }
    }
    .bn-footer-icon__item__background {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
      pointer-events: none;
      display: flex;
      color: #e4ded3;
    }
    .bn-icons {
      font-size: 0.7em;
      color: #2d2e33;
    }
  }
}


/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */

// 370px max height
@media #{$mq__max-h--370} {
  .bn-footer {
    // top references
    .bn-top-references {
      &.bn-top-references--mobile-v1 {
        display: none !important;
      }
    }
  }
}

// tablet
@media #{$mq__tablet} {
  .bn-footer {
    // top references
    .bn-top-references {
      &.bn-top-references--mobile {
        &.bn-top-references--mobile-v1 {
          &.bn-top-references--visible {
            display: block;
          }
        }
      }
    }
  }
}

// phablet
@media #{$mq__phablet} {
  .bn-footer {
    // top references
    .bn-top-references {
      &.bn-top-references--mobile {
        bottom: 20px;
        right: 20px;
      }
    }
    .bn-footer-icons a {
      margin: 0 15px;
    }
  }
}