/*  ==========================================================================
    MOBILE HEADER V4
    ========================================================================== */

@mixin mobile-header--v4() {
  @include mobile-header--v3();
  // controls
  .bn-controls {
    .bn-controls__left {
      order: 2;
      .bn-controls__left___top,
      .bn-controls__left___bottom {
        > .bn-grid__col {
          align-items: flex-end;
        }
      }
    }
    .bn-controls__right {
      align-items: flex-start;
      order: 1;
    }
  }
  // lang menu
  .bn-lang-menu {
    &.bn-lang-menu--above {
      ul {
        margin-left: 0;
        margin-right: -5px;
      }
    }
  }
  // control bar
  .bn-control-bar {
    .bn-control-bar__top,
    .bn-control-bar__center,
    .bn-control-bar__bottom {
      margin-left: calc(100% - #{$unit__wdt--mobile-header-control-bar});
    }
  }

  .bn-burger-menu_wrapper {
    &.bn-burger-menu_wrapper--top-references-v3 {
      @media only screen and (max-width: $bn-grid__bp--tablet) {
        display: none !important;
      }
    }
  }
  // minimized
  &.bn-mobile-header--minimized {
    // burger menu
    .bn-burger-menu_wrapper {
      .bn-burger-menu{
        &:last-child {
          border-left: 1px solid $clr__ci--primary;
          border-right-color: transparent;
        }
      }
      flex-direction: row-reverse;
    }
  }
}