/*  ==========================================================================
    QUOTE
    ========================================================================== */

/*
 * COLORS
 */

$module-clr__quote--border: $clr__ci--primary;
$module-clr__quote--signet: $clr__ci--primary;
$module-clr__quote--text: $clr__ci--primary;

/*
 * MODULE
 */

.bn-quote {
  .bn-quote__frame {
    border-bottom: 1px solid $module-clr__quote--border;
    border-top: 1px solid $module-clr__quote--border;
    padding: 40px;
    position: relative;
    .bn-quote__text {
      @include typo--quote($module-clr__quote--text);
      text-align: center;
    }
    .bn-quote__author {
      @include typo--quote-author($module-clr__quote--text);
      margin-top: 20px;
      text-align: center;
      font-weight: 700;
    }
    .bn-quote__signet {
      left: 0;
      position: absolute;
      text-align: center;
      top: 0;
      transform: translateY(-50%);
      width: 100%;
      z-index: 5;
      .bn-icon {
        background-color: $clr__white;
        color: $module-clr__quote--signet;
        font-size: 6em;
        padding: 0 5px;
      }
    }
  }
}


/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */

// phablet
@media #{$mq__phablet} {
  .bn-quote {
    .bn-quote__frame {
      padding: 20px;
      .bn-quote__author {
        margin-top: 10px;
      }
      .bn-quote__signet {
        .bn-icon {
          font-size: 2.5em;
        }
      }
    }
  }
}