/*  ==========================================================================
    BUTTONS
    ========================================================================== */

/*
 * COLORS
 */

/*
 * MODULE
 */
.bn-popup {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 1200;
  height: 100vh;
  transition: opacity 0.5s;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(15px);
  display: none;
  &.js-active {
    display: flex;
  }
  &__bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #000000;
    opacity: 0.55;
  }
  &__wrap {
    max-height: calc(100% - 6em);
    max-width: 100%;
    margin: 3em;
    position: relative;
    display: flex;
    overflow: hidden;
  }
  &__container {
    position: relative;
    background: $clr__white;
    overflow: auto;
    max-height: 100%;
    max-width: 100%;
    border-radius: 5px;
  }
  &__content {
    position: relative;
    z-index: 1;
    min-height: 100%;
    width: 62.5em;
    max-width: 100%;
    padding: 5.5em 6.5em;
  }
  &__closer {
    position: absolute;
    right: 2.5em;
    top: 2.5em;
    cursor: pointer;
    color: $clr__typo--primary;
    transition: transform 0.25s;
    z-index: 1;
    .bn-icon {
      font-size: 2em;
    }
  }
  &__headline {
    @include typo--subheadline($clr__typo--primary);
  }
  &__text {
    margin: 1em 0 2em 0;
    @include typo--rte($clr__typo--primary,$module-clr__text-pic-box-v2--accent-primary);
    > * {
      line-height: 1.6 !important;
    }
  }
  &__link {
    @include button--default();
  }
}


/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */

// phablet
@media #{$mq__phone} {
  .bn-popup {
    &__content {
      padding: 4em 2em;
    }
  }
}